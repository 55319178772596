import commonPaths from '../webpack/paths';

export const cssScriptTag =
  '<link href="https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700&display=swap" rel="stylesheet">';
export const jsScriptTag =
  '<script type="text/javascript" charset="UTF-8" src="' +
  commonPaths.publicPath +
  commonPaths.jsFolder +
  '/' +
  commonPaths.widgetVersion +
  '/{jsFileName}" async></script>';

export const urlWidgetTag = commonPaths.publicPath + '{widgetsHTMLFile}';

// export const widgetTypes = ['agenda', 'news', 'survey', 'suggestion', 'legal'];
export const widgetTypes = ['agenda', 'news'];

export const WINDOW = 'window';
export const URL = 'url';
export const ALL = 'all';

export const hideTypes = {
  'q': 'QR code',
  'd': 'Télécharger',
  'v': 'Consulter',
};

export const widgetParamsType = {
  agenda: WINDOW,
  news: WINDOW,
  diapo: URL,
  survey: ALL,
  suggestion: ALL,
  legal: URL
};

export const widgetsParams = {
  agenda: [
    'ecclesiaCityId',
    'ecclesiaAggloId',
    'zoom',
    'containerSize',
    'dateFilter',
    'itemPerPage',
    'imageMaxHeight',
    'backgroundColor',
    'vitesse',
    'displayMode',
    'truncate',
  ],
  news: [
    'ecclesiaCityId',
    'ecclesiaAggloId',
    'zoom',
    'containerSize',
    'itemPerPage',
    'imageMaxHeight',
    'backgroundColor',
    'ecclesiaTopicId',
    'ecclesiaActorId',
    'excludeActors',
    'vitesse',
    'displayMode',
    'truncate',
  ],
  diapo: ['zoom', 'vitesse', 'commune', 'interco', 'ecclesiaTopicId', 'ecclesiaActorId', 'excludeActors', 'type'],
  survey: ['commune', 'interco'],
  suggestion: ['commune', 'interco'],
  legal: ['ecclesiaCityId', 'zoom', 'hide', 'pmrMode', 'alignTo']
};

export const mappingParamsKeyType = {
  agenda: {
    ecclesiaCityId: 'ecclesiaCityIdEvent',
    ecclesiaAggloId: 'ecclesiaAggloIdEvent',
    zoom: 'zoomEvent',
    containerSize: 'containerSizeEvent',
    imageMaxHeight: 'imageMaxHeightEvent',
    itemPerPage: 'itemPerPageEvent',
    backgroundColor: 'backgroundColorEvent',
    dateFilter: 'dateFilterEvent',
    displayMode: 'displayModeEvent',
    vitesse: 'speedEvent',
    truncate: 'truncateEvent'
  },
  news: {
    ecclesiaCityId: 'ecclesiaCityIdNews',
    ecclesiaAggloId: 'ecclesiaAggloIdNews',
    zoom: 'zoomNews',
    containerSize: 'containerSizeNews',
    imageMaxHeight: 'imageMaxHeightNews',
    itemPerPage: 'itemPerPageNews',
    backgroundColor: 'backgroundColorNews',
    ecclesiaTopicId: 'ecclesiaTopicIdNews',
    ecclesiaActorId: 'ecclesiaActorIdNews',
    excludeActors: 'excludeActorsNews',
    displayMode: 'displayModeNews',
    vitesse: 'speedNews',
    truncate: 'truncateNews'
  },
  diapo: {},
  survey: {
    commune: 'communeSurvey',
    interco: 'intercoSurvey'
  },
  suggestion: {
    commune: 'communeSuggest',
    interco: 'intercoSuggest'
  },
};

export const widgetsDivIds = {
  agenda: '<div id="ecclesia_events"></div>',
  news: '<div id="ecclesia_news"></div>',
  survey: '<div id="ecclesia_surveys"></div>',
  suggestion: '<div id="ecclesia_suggestions"></div>',
};

export const widgetsNames = {
  agenda: 'Agenda',
  news: 'Journal',
  diapo: 'Diaporama',
  survey: 'Sondages',
  suggestion: 'Boîte à idées',
  legal: 'Actes Administratifs',
};

// output.filename from webpack.prod.js
export const widgetsJSFile = {
  agenda: 'agenda.js',
  news: 'actualite.js',
  diapo: 'diapo.js',
  survey: 'sondage.js',
  suggestion: 'suggestion.js',
};

// plugins.filename from webpack.common.js
export const widgetsHTMLFile = {
  agenda: 'agenda.html',
  news: 'actualite.html',
  diapo: 'diapo.html',
  survey: 'sondage.html',
  suggestion: 'suggestion.html',
};

const coeficientFigma = 1.1;

export const PADDING_MEDIUM = 20;
export const PADDING_SMALL = 15;

export const paddingContentContainer = {
  paddingLeft: 10,
  paddingRight: 10,
  paddingTop: 5,
  paddingBottom: 5,
};

export const constants = {
  NEWS_CARD: 100 * coeficientFigma,
  EVENT_CARD: 150 * coeficientFigma,
};

export const WEB_SSR_DOMAIN = 'web.app-ecclesia.com';