const dev = {
  apiRoot: 'https://staging.app-ecclesia.com',
  apiEvents: '/api/publications/events/', // '/events/',
  apiCities: '/api/locations/cities/', // '/locations/cities/',
  apiCategories: '/api/filter/categories/', // '/categories/',
  apiDirectories: '/api/mayor_services/directories/', // '/services/directories/',
  apiNews: '/api/publications/news/', // '/news/',
  apiAnecdote: '/anecdotes/', // '/anecdotes/',
  apiPoi: '/api/publications/poi/', // '/poi/',
  apiSurveys: '/api/mayor_services/survey/', // '/services/survey/',
  apiSurveyResults: '/api/mayor_services/surveyresults/', // '/services/surveyresults/',
  apiSurveyResultsAggregated: '/api/mayor_services/surveyresults_aggregated/', // '/services/surveyresults_aggregated/',
  apiSuggestbox: '/api/mayor_services/suggestbox/', // '/services/suggestbox',
  apiSuggestion: '/api/mayor_services/suggestions/', // '/services/suggestions',
  apiLegalFiles: '/services/legal_files', // '/services/legal_files',
};

const staging = {
  apiRoot: 'https://staging.app-ecclesia.com',
  apiEvents: '/api/publications/events/', // '/events/',
  apiCities: '/api/locations/cities/', // '/locations/cities/',
  apiCategories: '/api/filter/categories/', // '/categories/',
  apiDirectories: '/api/mayor_services/directories/', // '/services/directories/',
  apiNews: '/api/publications/news/', // '/news/',
  apiAnecdote: '/anecdotes/', // '/anecdotes/',
  apiPoi: '/api/publications/poi/', // '/poi/',
  apiSurveys: '/api/mayor_services/survey/', // '/services/survey/',
  apiSurveyResults: '/api/mayor_services/surveyresults/', // '/services/surveyresults/',
  apiSurveyResultsAggregated: '/api/mayor_services/surveyresults_aggregated/', // '/services/surveyresults_aggregated/',
  apiSuggestbox: '/api/mayor_services/suggestbox/', // '/services/suggestbox',
  apiSuggestion: '/api/mayor_services/suggestions/', // '/services/suggestions',
  apiLegalFiles: '/services/legal_files', // '/services/legal_files',
};

const prod = {
  apiRoot: 'https://admin.app-ecclesia.com',
  apiEvents: '/api/publications/events/', // '/events/',
  apiCities: '/api/locations/cities/', // '/locations/cities/',
  apiCategories: '/api/filter/categories/', // '/categories/',
  apiDirectories: '/api/mayor_services/directories/', // '/services/directories/',
  apiNews: '/api/publications/news/', // '/news/',
  apiAnecdote: '/anecdotes/', // '/anecdotes/',
  apiPoi: '/api/publications/poi/', // '/poi/',
  apiSurveys: '/api/mayor_services/survey/', // '/services/survey/',
  apiSurveyResults: '/api/mayor_services/surveyresults/', // '/services/surveyresults/',
  apiSurveyResultsAggregated: '/api/mayor_services/surveyresults_aggregated/', // '/services/surveyresults_aggregated/',
  apiSuggestbox: '/api/mayor_services/suggestbox/', // '/services/suggestbox',
  apiSuggestion: '/api/mayor_services/suggestions/', // '/services/suggestions',
  apiLegalFiles: '/services/legal_files', // '/services/legal_files',
};

let config = null;
console.log('api target env =' + process.env.TARGET_ENV);
if (process.env.TARGET_ENV === 'production') {
  config = prod;
} else if (process.env.TARGET_ENV === 'staging') {
  config = staging;
} else {
  config = dev;
}

export default {
  ...config,
};
